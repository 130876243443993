import store from '../../../app/store'
import { authedUserDomainFilter } from '../_helpers'

export function fetchWorkspaceUsersDisabled(
    selectedStack: StackDto | null,
    workspaceAccount: Account | null
) {
    const state = store.getState()
    const studioUser = state?.user?.studioUser
    // This query is only pertinent for stacker users, not external users
    return authedUserDomainFilter(selectedStack, workspaceAccount) || !studioUser
}

export function fetchAppUserListDisabled(
    selectedStack: StackDto | null,
    workspaceAccount: Account | null
) {
    return !selectedStack || authedUserDomainFilter(selectedStack, workspaceAccount)
}

export function fetchAppUserDisabled(
    stackId: string | undefined,
    workspaceAccount: Account | null
) {
    const state = store.getState()
    const user = state?.user?.user
    const studioUser = state?.user?.studioUser
    const isImpersonating = studioUser && studioUser?._sid !== user?._sid

    // don't want to try to fetch the app user if we're not on a stack or workspace (ie., on studio domain)
    return (!workspaceAccount && !stackId) || !studioUser || isImpersonating
}
